<template>
  <div class="comment">
    <Mpopup v-model="commentModal" @close="cancelComment" title="给快递员留言" 
      :maskClickable="false" height="72vh">
      <div class="comment-wrap">
        <div class='input'>
          <textarea cursor-spacing="100" v-model="comment" v-if="commentModal" :show-count="false"
            placeholder="无接触寄件，请将包裹放在小区/办公楼指定地点，若不知道指定地点，下完单后和快递员联系。" fixed maxlength="30"></textarea>
          <div class="count">{{comment.length || 0}} / 30</div>
        </div>
        <div class="comment-content">
          <div @click="addComment(item.lable)"
            :class="[comment.indexOf(item.lable) > -1 ? 'selected' : '']" 
            v-for="(item, index) in commentList" :key="index">{{item.lable}}
          </div>
        </div>
      </div>
      <div class="popup-bottom__btn">
        <button @click="confirmComment">完成</button>
      </div>
    </Mpopup>
  </div>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
export default {
  name: 'comment',
  components: {
    Mpopup
  },
  data() {
    return {
      comment: '',
      commentBackup: '',
      commentList: []
    }
  },
  props: {
    commentModal: {
      type: Boolean,
      default: false
    },
    commentContent: {
      type: String,
      default: ''
    }
  },
  watch: {
    commentModal(val) {
      if (val) {
        this.commentBackup = this.comment
      }
    },
    commentContent (val) {
      if (!val) {
        this.comment = ''
        this.commentBackup = ''
      }
    }
  },
  mounted () {
    this.getCmsgList()
  },
  methods: {
    getCmsgList () {
      let url = '/mobile/mobileapi.do?method=courierLeaMsgList'
      const params = {
        data: {
          coname: 'kuaidi100'
        },
        handleFail: false
      }
      this.$http.post(url, params).then((res)=> {
        this.commentList = res.data
      })
    },
    cancelComment () { // 取消留言
      this.$emit('update:commentModal', false)
      this.$emit('update:commentContent', this.commentBackup)
      this.comment = this.commentBackup
    },
    addComment (item) { // 添加留言
      if (this.comment.indexOf(item) > -1) {
        let i = item + ' '
        let str
        if (this.comment.indexOf(i) > -1) {
          str = this.comment.replace(i, '')
        } else {
          str = this.comment.replace(item, '')
        }
        this.comment = str
      } else {
        let str = this.comment + ' ' + item
        this.$nextTick(()=> {
          this.comment = str.replace(/\s+/, ' ').replace(/(^\s*)|(\s*$)/g, "").slice(0, 30)
        })
      }
    },
    confirmComment () {
      this.$emit('update:commentModal', false)
      this.$emit('update:commentContent', this.comment)
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-bottom__btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  button {
    width: 94.6%;
    height: 2.75rem;
    font-size: 1.0625rem;
    line-height: 2.75rem;
    border-radius: 0.25rem;
    background: #ff7f02;
    color: #fff;
    margin: 0.625rem;
    text-align: center;
  }
}
.selected {
  border: 1px solid #317ee7 !important;
  color: #317ee7 !important;
}
.comment {
  &-wrap {
    padding: 1.25rem 1.25rem 0;
    .input {
      position: relative;
      height: 4.0625rem;
      padding: 0.625rem 0.625rem;
      margin-bottom: 1.5625rem;
      border: 1px solid #eee;
      font-size: 0.875rem;
      textarea {
        width: 100%;
        height: 100%;
      }
      .count {
        position: absolute;
        right: 0.625rem;
        bottom: 0.3125rem;
        color: #888;
      }
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.875rem;
    div {
      display: border-box;
      padding: 0.25rem 0.625rem;
      margin-bottom: 1rem;
      border: 1px solid #eee;
      border-radius: 0.1875rem;
      text-align: center;
      color: #888;
      font-size: 0.875rem;
      margin-left:0.9375rem;
    }
    div.active {
      color: #317ee7;
      border-color: #317ee7;
    }
  }
}
</style>