<template>
  <div class="protocol">
    <div :style="{borderColor: agree ? '#efeff4' : '#c2c2c2'}" class="protocol-choose" @click="isAgree"><img v-if="agreeProtocol" src="https://cdn.kuaidi100.com/images/rebuild/protocolSelect.png"></div>
    <div class="protocol-name" @click="isAgree">我已阅读并同意<span @click.stop="showProtocol(2)">《快递100寄件服务与隐私协议》</span></div>
  </div>
</template>

<script>
export default {
  name: 'protocol',
  data() {
    return {
      agreeProtocol: false
    }
  },
  props: {
    agree: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    agree (val) {
      this.agreeProtocol = val
    }
  },
  mounted () {
    // this.agreeProtocol = localStorage.getItem('protocolMeta.agree') ? true : false
    if(localStorage.getItem('protocolMeta.agree')) {
      var oldT = new Date(+localStorage.getItem('protocolMeta.agree'))
      var nowT = new Date()
      var num = (nowT - oldT) / (1000 * 60 * 60 * 24)
      if (num > 30) { // 超过30天 去掉
        localStorage.removeItem('protocolMeta.agree')
      } else {
        this.agreeProtocol = true
      }
    }
    this.$emit('update:agree', this.agreeProtocol)
  },
  methods: {
    isAgree () {
      if(this.agreeProtocol) { // 取消同意
        this.agreeProtocol = false
        localStorage.removeItem('protocolMeta.agree')
        this.$emit('update:agree', this.agreeProtocol)
      } else {
        this.$emit('showProtocol')
      }
    },
    showProtocol () {
      this.$emit('showProtocol')
    }
  }
}
</script>

<style lang="scss" scoped>
.protocol {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  margin-bottom: 5.5rem;
  &-choose {
    width: 0.875rem;
    height: 0.875rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
    border: 1px solid;
    img {
      width: 0.9375rem;
      height: 0.9375rem;
    }
  }
  &-name {
    font-size: 0.875rem;
    color: #878787;
    padding-left: 0.4375rem;
    span {
      color: #317ee7;
    }
  }
}
</style>