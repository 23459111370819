<template>
  <div class="toptag">
    <div class="toptag-item" 
    v-for="item in toptagList" :key="item.id" 
    :class="{ active: item.id === activeId}"
    @click="goUrl(item)">
      {{item.title}}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      toptagList: [
        { id: 'jkd', title: '寄快递', name: 'dispatch' },
        { id: 'pljj', title: '批量寄件', name: 'dispatchbatch', label: '多件特惠' }, // 多件优惠
        // { id: 'jdj', title: '寄大件', name: 'normal' },
        // { id: 'gjgat', title: '国际/港澳台' }
      ],
      activeId: ''
    }
  },
  created() {
    const currentRoute = this.$route.name
    this.activeId = this.toptagList.find(item => item.name === currentRoute).id || 'jkd'
  },
  methods: {
    goUrl(item) {
      if(this.$route.name === item.name) return
      this.$router.replace({ name: item.name })
    }
  }
}
</script>
<style lang="scss" scoped>
.toptag {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  &-item {
    width: 25%;
    height: 1.7857rem;
    line-height: 1.7857rem;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    font-size: 1rem;
    color: #262626;
  }
  .active {
    font-weight: bold;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      width: 20vw;
      height: .3571rem;
      border-radius: .3571rem;
      background: linear-gradient(90deg, #317EE7 -2%, rgba(49,126,231,0.00) 103%);
      left: .7143rem;
      bottom: .3571rem;
    }
  }
}
</style>