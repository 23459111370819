<template>
	<div class="main" :class="[marginTop ? 'marginTop' : '', ]" :style="mainStyle" @click="bindClick">
	  <div class="main-left" :class="[left ? 'pl28' : '']">{{title}}</div>
    <div class="main-right" :class="[show ? 'hasValue' : '']"><span v-if="!show">{{desc}}</span><slot v-if="show"></slot></div>
    <div class="main-line" v-if="haveLine"></div>
	</div>
</template>

<script>
	export default {
		name: 'info-select',
		props: {
			title: {
				type: String,
				default: ''
      },
      /* placeholder */
      desc: {
				type: String,
				default: ''
      },
      /* 是否显示 */
      show: {
        type: String,
				default: ''
      },
      /* 是否有线 默认有 */
      haveLine: {
        type: Boolean,
        default: true
      },
      /* 是否下移10px 默认否 */
      marginTop: {
        type: Boolean,
        default: false
      },
      left: {
				type: Boolean,
				default: false
      },
      mainStyle: {
        type: String,
        default: '',
      }
		},
		data() {
			return {
				
			}
		},
		watch: {
			
		},
		created() {
			
		},
		methods: {
      bindClick () {
        this.$emit('selectFun')
      }
		}
	}
</script>

<style lang="scss" scoped>
@mixin common-block {
  margin-left: 10px;
  margin-right: 10px;
  background: #fff;
  border-radius: 0.25rem;
}
.marginTop {
  margin-top: 0.625rem;
}
.pl28 {
  padding-left: 0.875rem !important;
}
.main {
  @include common-block;
  position: relative;
  display: flex;
  padding: 0.625rem 0;
  height: 3rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.25rem;
    display: block;
    width: 0.4375rem;
    height: 0.4375rem;
    border-top: 0.09375rem solid #c4c4c4;
    border-right: 0.09375rem solid #c4c4c4;
    transform: rotate(45deg);
  }
  &-left {
    color: #888;
    font-size: 1rem;
    padding-left: 0.5625rem;
  }
  &-right {
    color: #bebebe;
    font-size: 1rem;
    padding-right: 1.5625rem;
  }
  .hasValue {
    color: #333;
    font-weight: bold;
  }
  &-line {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #efeff4;
  }
}
</style>