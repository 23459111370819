import { render, staticRenderFns } from "./protocolCom.vue?vue&type=template&id=a32ae6ee&scoped=true"
import script from "./protocolCom.vue?vue&type=script&lang=js"
export * from "./protocolCom.vue?vue&type=script&lang=js"
import style0 from "./protocolCom.vue?vue&type=style&index=0&id=a32ae6ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a32ae6ee",
  null
  
)

export default component.exports